import { NextPage } from 'next';
import { Star, PreviewOpen, ArrowRight, Pic } from '@icon-park/react';
import styles from '../styles/Item.module.scss';
import Link from 'next/link'
import { Avatar, Tag } from 'antd';
import { Item as item } from '../../@types/index'
import { State, Switch } from '../../@types/reducer';
import { useSelector } from 'react-redux';
// import LazyLoad from 'react-lazyload';
import { toWebp } from '../../utils';
import React, { useMemo } from 'react';
import { pattern } from '../../constants/heads';
import NoSsr from '../NoSsr';
type Props = {
    data: item,
    imgUrl: string,
    switch: Switch,
    isLazyLoad?: boolean
};

const Item: NextPage<Props> = (props) => {
    const { isXs, isXxl } = useSelector((state: State) => state.responsive)
    const isWebp = useSelector((state: State) => state.isWebp)
    const { _id, pinyin, visit, title, brief, logo, label, type, like_status, like } = props.data
    const isSwitch = props.switch
    const imgUrl = props.imgUrl
    return useMemo(() => (
        <div className={styles.block}>
            <div className="one" style={isSwitch.data ? {} : { padding: '15px' }} data-id={_id}>
                <NoSsr>
                    {
                        isSwitch.data && <div className="btn">
                            <div data-id={_id} data-like={true}>
                                <Star data-id={_id} data-like={true} theme={like_status ? "filled" : "outline"} size="14" fill={like_status ? '#ffdf29' : "#999"} strokeWidth={3} strokeLinejoin="miter" />
                                {like}
                            </div>
                            <div data-id={_id}>
                                <PreviewOpen theme="outline" size="18" fill="#999" strokeWidth={3} strokeLinejoin="miter" />
                                {visit}
                            </div>
                        </div>
                    }
                </NoSsr>
                <div className="text" data-id={_id}>
                    {
                        isSwitch.icon && <div className="logo">
                            {/* {props.isLazyLoad ? <LazyLoad once height={40} offset={40} placeholder={<Avatar style={{ background: '#e8e8e8' }} size={40} icon={<Pic theme="outline" size="16" fill="#fff" strokeWidth={3} />} />} throttle={200}>
                                <img
                                    src={isWebp ? imgUrl + toWebp(logo) : imgUrl + logo}
                                    alt={title}
                                />
                            </LazyLoad> :  */}
                            <img
                                loading={props.isLazyLoad?"lazy":"eager"}
                                width="40"
                                height="40"
                                src={isWebp ? imgUrl + toWebp(logo) : imgUrl + logo}
                                alt={title}
                            />
                            {/* } */}
                        </div>
                    }
                    <div className="title" data-id={_id}>
                        <h4 style={isSwitch.brief ? {} : { lineHeight: '40px', height: '40px', padding: "0" }}>
                            {title}
                            <NoSsr>
                                {isSwitch.tag && label && label.map((item, index) => (<Tag key={index}>{item}</Tag>))}
                            </NoSsr>
                        </h4>
                        {isSwitch.brief && <p >{brief}</p>}
                    </div>
                </div>
                {
                    type == 1 && isXxl && <div className="access" style={!isSwitch.data ? { marginTop: '0' } : {}}>
                        <Link prefetch={false} href={`/details/${pattern ? _id : pinyin}`} >
                            <a target="_blank" title={title} >
                            <NoSsr> <ArrowRight theme="outline" size="24" fill="#8590a6" strokeWidth={3} strokeLinejoin="miter" strokeLinecap="butt" /> </NoSsr>
                            </a>
                        </Link>
                    </div>
                }
            </div>
        </div>

    ), [like, like_status, isSwitch, isXxl])
}

Item.defaultProps = {
    isLazyLoad: false
}

export default Item;